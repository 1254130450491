<template>
	<div class="contest">
		<div
			v-if="!contest"
			class="preloader"
			style="margin-top: 200px"
		/>

		<template v-else>
			<!-- Главный блок -->
			<div class="contest-cover">
				<div class="contest-cover-pic">
					<div
						class="image-cover-wrap"
						:style="'/img/stajer.png' | bgrImageCover"
					/>
				</div>
				<div class="contest-cover-content">
					<div class="content">
						<Breadcrumb :page-name="contest.name" />
						<div class="grid">
							<div class="box">
								<h3 class="page-title">
									{{ contest.name }}
								</h3>
								<div
									class="text"
									v-html="contest.description"
								/>
								<button
									class="button blue"
									@click="participate"
								>
									Принять участие
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Главные кнопки -->
			<section class="section-simple wow">
				<div class="content">
					<div class="main-button-group">
						<div class="grid">
							<div class="box">
								<button
									class="start-navigation-button orange"
									@click="() => $scrollTo($refs.videos)"
								>
									<span>Работы участников</span>
								</button>
							</div>
							<div class="box">
								<button
									class="start-navigation-button blue"
									@click="participate"
								>
									<span>Принять участие</span>
								</button>
							</div>
							<div class="box">
								<a
									:href="'/staticfiles/media/trainee.pdf'"
									target="_blank"
									class="start-navigation-button gray"
								>
									<span>Положение о конкурсе</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- О конкурсе -->
			<section
				id="anchor-about"
				class="section-simple light wow"
			>
				<div class="content">
					<h3 class="block-title">
						О конкурсе
					</h3>
					<div
						class="text md dark"
						v-html="contest.about"
					/>
				</div>
			</section>

			<section class="section-simple wow">
				<div class="content">
					<h3 class="block-title">
						Этапы видеоконкурса
					</h3>
					<div class="stages-block">
						<div class="stages-line" />
						<div class="stages">
							<div class="stage">
								<div class="stage-index">
									2 этап
								</div>
								<div class="stage-title">
									Прием заявок
								</div>
								<div class="stage-date">
									до 28 ноября
								</div>
							</div>
							<div class="stage">
								<div class="stage-index">
									3 этап
								</div>
								<div class="stage-title">
									Обучение по производству видеоконтента: от создания идеи до получения готового продукта (в онлайн формате и займет не более 2 часов)
								</div>
								<div class="stage-date">
									29 ноября - 5 декабря
								</div>
							</div>
							<div class="stage">
								<div class="stage-index">
									4 этап
								</div>
								<div class="stage-title">
									Прием готовых видеороликов
								</div>
								<div class="stage-date">
									15 декабря
								</div>
							</div>
							<div class="stage">
								<div class="stage-index">
									5 этап
								</div>
								<div class="stage-title">
									Голосование жюри
								</div>
								<div class="stage-date">
									16-20 декабря
								</div>
							</div>
							<div class="stage">
								<div class="stage-index">
									6 этап
								</div>
								<div class="stage-title">
									Объявление результатов конкурса
								</div>
								<div class="stage-date">
									до 25 декабря
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- Победителям -->
			<section class="section-simple light wow">
				<div class="content">
					<h3 class="block-title">
						Победителям и участникам
					</h3>
					<div
						class="text md dark"
						v-html="contest.present"
					/>
					<button
						class="button blue winners-button"
						@click="participate"
					>
						Принять участие
					</button>
				</div>
			</section>

			<!-- Этапы -->


			<!-- Работы участников -->
			<section
				ref="videos"
				class="section-simple wow"
			>
				<div class="content">
					<div
						class="section-title-wrap"
					>
						<div class="grid">
							<div class="box">
								<h3 class="block-title">
									Работы участников
								</h3>
							</div>
							<div class="box">
								<!-- <button class="button blue" @click="participate">Принять участие</button> -->
							</div>
						</div>
					</div>
					<div class="contest-videos">
						<div
							v-if="contest.videos_list.length"
							class="bar"
						>
							<div class="search">
								<div class="search-button" />
								<input
									v-model="filter.search"
									type="text"
									class="search-field"
									placeholder="Найти участника"
								>
							</div>
						</div>
						<div
							v-if="contest.videos_list.length"
							class="contest-videos-filters"
						>
							<div class="contest-videos-filters-list">
								<label class="checkbox-filter">
									<input
										type="checkbox"
										:checked="!filter.cbx.length"
										@change="selectAllCbx"
									>
									<span>Все номинации</span>
								</label>
								<label
									v-for="item in contest.nominations"
									:key="item.id"
									class="checkbox-filter"
								>
									<input
										v-model="filter.cbx"
										type="checkbox"
										:value="item.id"
									>
									<span>{{ item.name }}</span>
								</label>
							</div>
						</div>
						<div
							v-if="contest.videos_list && !contest.videos_list.length && !filter.search"
							class="empty-catalog"
						>
							<div class="empty-catalog-text">
								25 декабря 2021 года тут появятся конкурсные работы
								участников
							</div>
						</div>
						<div
							v-else-if="video.items && !video.items.length && filter.search"
							class="empty-catalog"
						>
							<div class="empty-catalog-text">
								По вашему запросу ничего не найдено
							</div>
						</div>
						<template v-else>
							<div
								v-if="video.isLoading || video.isLiking"
								class="preloader sm"
								style="margin-top: 100px"
							/>
							<div
								v-else
								class="grid"
							>
								<div
									v-for="item in video.items"
									:key="item.id"
									class="box"
								>
									<div class="video">
										<div
											class="video-body"
											@click="modalOpen('video_', { src: item.video_cloud_url || item.video })"
										>
											<div class="video-pic">
												<div class="video-tag">
													{{
														item.nominations.name || contest.nominations.find(x => x.id === item.nominations).name
													}}
												</div>
												<div
													v-if="item.video_preview"
													class="image-cover-wrap"
													:style="item.video_preview | bgrDynamicImageCover"
												/>
											</div>
											<h2 class="video-title">
												{{ item.name }}
											</h2>
										</div>
										<div class="video-person">
											{{ item.team_name }}
										</div>
										<div class="video-company">
											{{ item.company_name }}
										</div>
										<div class="cells">
											<div class="cell">
												<div class="video-votes">
													<img
														v-if="isAvailableVote(item.id)"
														:src="'/img/icons/icon-heart-hollow-bold.svg' | prodSrcImage"
														alt="Нет лайка"
														@click="vote(item.id)"
													>
												</div>
											</div>
											<!-- <div class="cell">
                                                <button
                                                    class="button sm"
                                                    :class="{ 'button-hollow': isAvailableVote(item.id), 'blue': !isAvailableVote(item.id) }"
                                                    v-if="!idVoice || idVoice !== item.id"
                                                    @click="vote(item.id)"
                                                    >{{ isAvailableVote(item.id) | buttonVoteText }}</button>
                                                <div class="button-hollow sm button-preloader" v-else-if="idVoice === item.id">
                                                    Голосовать
                                                    <div class="button-preloader-icons">
                                                        <div class="button-preloader-icon"/>
                                                        <div class="button-preloader-icon"/>
                                                        <div class="button-preloader-icon"/>
                                                    </div>
                                                </div>
                                            </div> -->
										</div>
									</div>
								</div>
							</div>
							<div
								v-if="video.pagination.ttl > 1"
								class="center"
							>
								<ul class="pagination">
									<li
										v-if="video.pagination.init"
										@click="prevPage"
									>
										<span>&lt;</span>
									</li>
									<li
										v-for="(item, index) in pages"
										:key="index"
										:class="{ active: video.pagination.crnt === item }"
										@click="selectPage(item, index)"
									>
										<span>{{ item }}</span>
									</li>
									<li v-if="video.pagination.ttl - video.pagination.init > 4">
										<span>...</span>
									</li>
									<li
										v-if="isShowTotal"
										:class="{ active: video.pagination.crnt === video.pagination.ttl }"
										@click="selectPage(video.pagination.ttl)"
									>
										<span>{{ video.pagination.ttl }}</span>
									</li>
									<li
										v-if="isShowNext"
										@click="nextPage"
									>
										<span>&gt;</span>
									</li>
								</ul>
							</div>
						</template>
					</div>
				</div>
			</section>

			<!-- Жюри -->
			<section
				v-if="contest.juries.length"
				class="section-simple light wow"
			>
				<div class="content">
					<h3 class="block-title">
						Жюри конкурса
					</h3>
					<div class="jury-group">
						<div class="grid">
							<div
								v-for="jury in contest.juries"
								:key="jury.id"
								class="box"
							>
								<div class="jury">
									<img
										:src="jury.image_jury[0].image"
										:alt="`Аватар ${jury.name}`"
										class="jury-avatar"
									>
									<div class="jury-content">
										<div class="jury-name">
											{{ jury.name }}
										</div>
										<div class="jury-job">
											{{ jury.description }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- Задать вопрос -->
			<section class="section-simple wow">
				<div class="content">
					<h3 class="block-title">
						Задать вопрос
					</h3>
					<div class="contacts">
						<div class="contact">
							Все вопросы можно уточнить, связавшись с нами по почте: <a
								href="mailto:info@mail.lift-bf.ru"
								class="link-dashed"
							>info@mail.lift-bf.ru</a>
						</div>
					</div>
					<VueSlickCarousel
						v-if="contest.image_partners.length"
						v-bind="partnerSlider"
						class="partners-slider"
						:lazy-load="'progressive'"
					>
						<div
							v-for="partner in contest.image_partners"
							:key="partner.id"
							class="slide"
						>
							<div class="partner">
								<img
									:src="partner.image"
									alt="Логотип партнера конкурса"
									class="pic"
								>
							</div>
						</div>
					</VueSlickCarousel>
				</div>
			</section>
		</template>
	</div>
</template>

<script>

import Cnt from '@/api/contest'
import Breadcrumb from '@/components/Breadcrumb';

export default {
    components: {
        Breadcrumb
    },
    filters: {
        buttonVoteText(val) {
            if (val) {
                return 'Голосовать'
            } else {
                return 'Снять голос'
            }
        }
    },
    data: () => ({
        filter: {
            search: '',
            cbx: new Array()
        },
        video: {
            items: null,
            count: null,
            liked: new Array(),
            isLoading: false,
            isLiking: true,
            pagination: {
                init: 0,
                crnt: 1,
                ttl: undefined
            }
        },
        idVoice: 0
    }),
    computed: {
        isLogged() {
            return this.$store.getters['user/isLoggedIn']
        },
        // userID() {
        //     return this.$store.getters['user/userID']
        // },
        contest() {
            return this.$store.state.cnt.contest
        },
        partnerSlider() {
            return {
                ...this.carouselSettings,
                'slides-to-show': 5,
                'slides-to-scroll': 5
            }
        },
        isShowTotal() {
            if (this.video.count) return Math.ceil(this.video.count / 15) > 3
            return false
        },
        isShowNext() {
            return (this.video.pagination.init !== this.video.pagination.ttl - 4) && this.isShowTotal
        },
        pages() {
            let counter = 1
            let temp = []
            let max = Math.ceil(this.video.count / 15) < 3 ? Math.ceil(this.video.count / 15) : 3
            while (counter <= max) {
                temp.push(this.video.pagination.init + counter)
                counter++
            }
            return temp
        }
    },
    watch: {
        filter: {
            handler() {
                this.video.pagination.init = 0
                this.video.pagination.crnt = 1
                this.getAllVideos()
            },
            deep: true
        }
    },
    created() {
        !this.contest && this.$store.dispatch('cnt/getContest','mama-ya-stazhor')
        // this.getAllVideos()
        // Cnt.getAllFans()
        //     .then(response => this.video.liked = response.data.filter(x => x.likes.some(y => y.id === this.userID)).map(x => x.id))
        //     .then(() => this.video.isLiking = false)
    },
    methods: {
        getAllVideos({
            search = this.filter.search,
            nominations = this.filter.cbx.filter(x => !!x).join(),
            page = this.video.pagination.crnt
        } = new Object()) {
            this.video.isLoading = true
            Cnt.getAllVideos({
                search,
                nominations,
                page
            })
                .then(response => {
                    this.video.items = response.data.results
                    this.video.count = response.data.count
                    this.video.pagination.ttl = Math.ceil(this.video.count / 15)
                })
                .finally(() => this.video.isLoading = false)
        },
        participate() {
            if (this.$store.getters['user/isLoggedIn']) {
              this.modalOpen('conReqTrainee',true)
            }
            else {
              this.modalOpen('noAuth')
            }
        },
        selectAllCbx(event) {
            if (event.target.checked) this.filter.cbx = new Array()
        },
        isAvailableVote(id) {
            if (this.video.liked.includes(id)) {
                return false
            } else {
                return true
            }
        },
        selectPage(item, id) {
            this.video.pagination.crnt = item
            this.getAllVideos()
            this.$scrollTo(this.$refs.videos)
            if (this.video.pagination.ttl === item) {
                if (this.video.pagination.ttl >= 4) this.video.pagination.init = this.video.pagination.ttl - 4
                else this.video.pagination.init = 0
            } else if (id === 0 && item > 1) this.video.pagination.init--
            else if (id === 2 && item < this.video.pagination.ttl - 1) this.video.pagination.init++
        },
        prevPage() {
            this.video.pagination.init--
            this.$scrollTo(this.$refs.videos)
        },
        nextPage() {
            this.video.pagination.init++
            this.$scrollTo(this.$refs.videos)
        }
    }
}
</script>

<style lang="less">
@import "../../../assets/styles/pages/contest";
.contest-videos .video-pic {
    cursor: pointer
}
.winners-button {
	display:flex;
	margin:70px auto 0 ;
}
</style>
